<template>
  <div class="header fixed">

    <div class="header-logo"></div>

    <div class="header-breadcrumb">
      <Breadcrumb/>
    </div>
    <div class="flex items-center mr-4" v-if="$route.name==='project' && lastEdit">
      <div class="m-auto flex flex-col">
        <span class="text-xs font-bold text-blue-700">Ultima modifica</span>
        <span class="text-xs">{{ lastEdit.user.name + ' ' + lastEdit.user.surname }} il
          <strong>{{ format(utcToZonedTime(lastEdit.date), "dd/MM/yyyy HH:mm", {timeZone: 'Europe/Paris'}) }}</strong>
        </span>
      </div>
    </div>
    <div class="header-inner">
      <div class="header-inner__content">
        <el-button :loading="isLoading" v-if="$route.name==='project'" class="mr-4" :disabled="isLoading"
                   @click.stop="onSupportRequest"
                   type="primary">
          <i class="el-icon-s-custom pr-2"></i>Richiedi supporto
        </el-button>
        <!--Avatar-->
        <el-dropdown
            class="avatar-container ml-2"
            trigger="hover"
        >
          <el-avatar class="capitalize">
            {{ user ? user.name.charAt(0) : 'N/D' }}
          </el-avatar>
          <!--Dropdown Options-->
          <el-dropdown-menu
              slot="dropdown"
              class="user-dropdown"
          >
            <!-- <router-link to="/profile">
              <el-dropdown-item>
                Profilo
              </el-dropdown-item>
            </router-link> -->
            <router-link to="/change-password">
              <el-dropdown-item>
                Cambia Password
              </el-dropdown-item>
            </router-link>
            <el-dropdown-item
                divided
                @click.native="logout"
            >
              <span style="display:block;">Log Out</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb';
import authService from '@/modules/auth/services/auth.services';
import {mapGetters} from 'vuex'
import projectsService from "@/modules/projects/services/projects.services";
import eventHub from "@/lib/eventHub";
import {format, utcToZonedTime} from 'date-fns-tz'

export default {
  name: 'Header',
  components: {
    Breadcrumb
  },
  data() {
    return {
      format, utcToZonedTime,
      isLoading: false,
      lastEdit: null,
    };
  },
  mounted() {
    this.lastEdit = null;
    eventHub.$on('project-fetch', (project) => {
      this.lastEdit = project ? project.last_edit : null;
    })
  },
  beforeDestroy() {
    eventHub.$off('project-fetch')
  },
  methods: {
    /**
     * @desc on logout
     * @returns {Promise<void>}
     */
    async logout() {
      await authService.logout()
    },
    /**
     * @desc On support request
     * @returns {Promise<void>}
     */
    async onSupportRequest() {
      this.isLoading = true;
      try {
        await projectsService.supportRequest(this.$route.params.id);
        this.$message.success('Richiesta di supporto inviata con successo')
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

}

</script>

<style lang="scss" scoped>

@import '@/styles/_variables.scss';

.header {
  height: $header-height;
  @apply overflow-hidden relative bg-white z-10 w-full flex justify-end px-2;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
  transition: width 0.28s;

  &-logo {
    min-height: 100%;
    width: 130px;
    background-image: url("../../assets/logoOspedaleFuturo.jpg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-breadcrumb {
    @apply m-auto ml-2 content-center flex items-center h-full;
  }

  &-inner {
    @apply flex;
    @apply flex-row;
    @apply relative;
    padding-left: 1rem;
    padding-right: 1rem;
    @apply content-end;
    @apply bg-white;
    @apply border-l;

    &__content {
      @apply flex;
      @apply flex-row;
      @apply w-full;
      @apply content-end;
      @apply items-center;
      @apply bg-white;
    }
  }

  &.fixed {
    @apply fixed;
    @apply top-0;
    @apply right-0;
  }
}
</style>
