<template>
  <!--Main-->
  <div id="master">
    <!--Main Container-->
    <div class="main-container">
      <!--Header-->
      <Header/>
      <!--App-->
      <AppContent/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/layout/Header';
import AppContent from '@/components/layout/AppContent';

export default {
  name: "master",
  components: {
    Header, AppContent
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

#master {
  .main-container {
    min-height: 100%;
    transition: margin-left .28s;
    position: relative;
  }
}
</style>
