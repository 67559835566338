import Api from '@/plugins/api';

export default {

    /**
     * @desc Make login
     * @returns {Promise<*>}
     * @param payload
     */
    async login(payload) {
        return (await Api.post('/login', payload));
    },
    /**
     * @desc Make Logout
     * @returns {Promise<*>}
     */
    async logout() {
        return (await Api.get('/logout'));
    },

    /**
     * @description Make user registration
     * @param payload
     * @returns {Promise<*>}
     */
    async register(payload) {
        return await Api.post('/register', payload)
    },

    async changePassword(payload) {
        return (await Api.post('/change-password', payload));
    },
};
