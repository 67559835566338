<template>
  <section class="app-content">
    <div class="flex w-full">
<!--      <transition name="fade" mode="out-in">-->
        <router-view />
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppContent',
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.app-content {
  /*50 = navbar  */
  height: 100vh; //calc(100vh - #{$header-height});

  width: 100%;
  position: relative;
  overflow: auto;
  background-color: $background-color;
  @apply flex flex-col;
  //padding-top: calc(2rem + #{$header-height});
  padding-top: $header-height;
}

.fade-enter-active, .fade-leave-active {
  transition: all .2s ease-in-out;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  transform: translate(0, 30px);
  opacity: 0;
}
</style>
