import store from '@/store';
import router from "../../../router";
import authApi from './auth.api';
const storeModule = 'auth';

export default {

    /**
     * @desc Attempt login
     * @param user
     * @returns {Promise<void>}
     */
    async login(user) {
        return await authApi.login(user)
    },
    /**
     * @desc Change password
     * @returns {Promise<*>}
     * @param user_id
     * @param payload
     */
    async changePassword(payload) {
        return await authApi.changePassword(payload);
    },

    /**
     * @desc Logout
     * @returns {Promise<void>}
     */
    async logout() {
        return await authApi.logout().then(() => {
            router.push('/login').then(() => {
                store.dispatch(`${storeModule}/resetState`);
            })
        })
    },

    /**
     * @description Register new user
     * @param user
     * @returns {Promise<*>}
     */
    async register(user) {
        return await authApi.register(user)
    }
};
